@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');

body {
    background-color: var(--bg-blinking-color-2);
    font-family: var(--font-family-1);
    text-align: justify;
    margin: 0px;
    padding: 0px;
    font-size: 100%;
    line-height: 28px;
    overflow-x: hidden;

}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font-family-1);
    margin: 0px;
    padding: 0px;
    line-height: 32px;
    font-weight: var(--font-weight-400);
}

ul {
    margin: 0px;
    padding: 0px;
}

ul li {
    list-style: none;
}


p {
    font-size: var(--font-size-normal-18px);
    font-weight: var(--font-weight-400);
    line-height: 28px;
    color: var(--text-color-2);
    margin-bottom: 10px;
}

a {
    text-decoration: none;
    font-style: normal;
    color: var(--text-color-2);

}

.text-yellow {
    color: var(--bg-blinking-color-10);
}

.text-light-white {
    color: var(--bg-blinking-color-9);
}

.font-14 {
    font-size: 14px;
    color: var(--bg-blinking-color-3);
    line-height: 8px;
}

.font-14 .fa {
    margin-right: 7px;
}

.font-12 {
    font-size: 12px;
}

.font-12 .fa {
    margin-right: 7px;
}