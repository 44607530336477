/* //// Home Services css ///// */

.HomeServices {
    padding: 80px 0px;
    background-color: var(--bg-blinking-color-8);
}

.HomeServices h2 {
    line-height: 52px;
}


.HomeServices img {
    margin: 30px 0px;

}

.padding-lr p {
    color: #fff;
    margin-bottom: 35px;
}

.HomeServices-box-1 {
    background-color: var(--bg-blinking-color-2);
    /* max-width: 170px; */
    padding: 20px;
    border-radius: 10px;
    position: relative;
    box-shadow: -2px 0px 28px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(231, 231, 231);
    margin-top: 30px;
    transition: all 0.3s linear;
}

.HomeServices-box-1 .HomeServices-inner-box {
    background-color: var(--bg-blinking-color-2);
    box-shadow: -2px 0px 15px 0px rgba(0, 0, 0, 0.06);
    height: 56px;
    width: 56px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 14px;
    border-radius: 100%;
    transition: all 0.3s linear;
}

.HomeServices-box-1 h5 {
    font-size: var(--font-size-normal-20px);
    font-weight: 700;
    color: var(--text-color-4);
    margin: 0 0 2px;
    transition: none;
    transition: all 0.3s linear;
}

.HomeServices-box-1 p {
    margin: 10px 0px 25px 0px;
    font-size: var(--font-size-normal-14px);
    line-height: 24px;
    transition: none;
}


.HomeServices-box-1:hover .HomeServices-inner-box img {
    transform: rotate(360deg);
}

.HomeServices-box-1:hover h5,
.HomeServices-inner-box:hover p {
    color: var(--text-color-1);
}

.HomeServices-box-1:hover {
    background-image: -moz-linear-gradient(0deg, rgb(244, 170, 54) 0%, rgb(243, 115, 53) 100%);
    background-image: -webkit-linear-gradient(0deg, rgb(244, 170, 54) 0%, rgb(243, 115, 53) 100%);
    /* background-image: -ms-linear-gradient(0deg, rgb(244, 170, 54) 0%, rgb(243, 115, 53) 100%);*/
    border-color: rgb(231, 231, 231);
}


.HomeServices .btn_one {
    color: var(--text-color-1);
    margin: 10px 0px;
}


/* Responsive.css */

@media (max-width: 374.98px) {

    .HomeServices-box-1 {
        margin-left: 10px !important;
    }



}

@media (min-width: 375.98px) and (max-width: 575.98px) {

    .HomeServices-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 576px) and (max-width: 767.98px) {

    .HomeServices-box-1 {
        margin-left: 10px !important;
    }



}


@media (min-width: 768px) and (max-width: 991.98px) {

    .HomeServices-box-1 {
        margin-left: 10px !important;
    }




}


@media (min-width: 992px) and (max-width: 1199.98px) {}


@media (min-width: 1200px) {}